
/******************************** Import Package ************************************/
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";

/******************************* Import Pages ************************************/
import NotFound from "../Pages/NotFound";
import GoogleAuth from "../Pages/GoogleAuth";
import TimeOut from "../Pages/TimeOut";
import Login from "../Pages/Login";
/******************************* Import Components *********************************/
import Notification from "../Components/Notification";

/******************************* Import Constants **********************************/
import { env, hostConfig, idleTime } from "../Config";
/******************************* Import Styles **********************************/
import { useAppStyles } from "./App.Style";
import "./App.css";

import Users from "../Pages/Users";
import { updateUserData } from "../Api/update";
import { getUserSessionData } from "../Utils/session";
import { getUserList, getFavorites } from "../Api/list";
import { postUserData, postFavorites } from "../Api/create";
import { deleteUser, deleteFavorites } from "../Api/delete";

import AddUser from "../Pages/Users/AddUser"
import EditUser from "../Pages/Users/EditUser"
import EditMyContact from "../Pages/Users/EditMyContact"
import UserDetails from "../Containers/UserDetails/UserDetails"
import Favorites from "../Pages/Favorites/Favorites"
import Pcp from "../Pages/Pcp/Pcp";
import Unauthorized from "../Pages/Unauthorized";

/**********************************Theme color****************************************/
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#9acad6',
      main: '#4e9a98',
      dark: '#87bcb4',
      contrastText: '#fff',
    },
  },
});
/***********************************App****************************************/
export default function App() {
  const classes = useAppStyles();
  const [users, setUsers] = useState([]);
  const [alphaIndexContacts, setAlphaIndexContacts] = useState({
    a: 0, b: 0, c: 0, d: 0, e: 0, f: 0, g: 0,
    h: 0, i: 0, j: 0, k: 0, l: 0, m: 0, n: 0, o: 0,
    p: 0, q: 0, r: 0, s: 0, t: 0, u: 0, v: 0, w: 0,
    x: 0, y: 0, z: 0
  })
  const [alphaIndexPcp, setAlphaIndexPcp] = useState({
    a: 0, b: 0, c: 0, d: 0, e: 0, f: 0, g: 0,
    h: 0, i: 0, j: 0, k: 0, l: 0, m: 0, n: 0, o: 0,
    p: 0, q: 0, r: 0, s: 0, t: 0, u: 0, v: 0, w: 0,
    x: 0, y: 0, z: 0
  })
  const [alphaIndexFavourites, setAlphaIndexFavourites] = useState({
    a: 0, b: 0, c: 0, d: 0, e: 0, f: 0, g: 0,
    h: 0, i: 0, j: 0, k: 0, l: 0, m: 0, n: 0, o: 0,
    p: 0, q: 0, r: 0, s: 0, t: 0, u: 0, v: 0, w: 0,
    x: 0, y: 0, z: 0
  })

  const [favorites, setFavorites] = useState([]);
  const [pcp, setPcp] = useState([]);

  const [search, setSearch] = useState([]);
  const [favId, setFavId] = useState(false);
  const [favArray, setFavArray] = useState([]);

  const [values, setValues] = useState([]);
  const loggedUser = getUserSessionData();
  const [currentUser, setCurrentUser] = useState(loggedUser);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  let sortBy = "lastName:asc";

  const inactivityTime = () => {
    let time;

    const logout = () => {
      window.location.href = "/time-out"
    };

    const resetTimer = () => {
      clearTimeout(time);
      time = setTimeout(logout, idleTime);
    };
    window.onload = resetTimer;
    window.addEventListener("load", resetTimer, true);
    var events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
      "touchmove",
      "wheel",
      "select",
    ];
    events.forEach(function (name) {
      document.addEventListener(name, resetTimer, true);
    });
  };

  window.onload = () => {
    inactivityTime();
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("loggedUser"))) {
      getUserData();
      getFavoritesData()
      getPcpData()
    }
    // all functions should run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const findIndex = (data, dataSource) => {
    let prevCharacter = "#"
    let alphaIndex = {};
    let favArray = [];
    let lastChar = "";

    data.forEach((item, i) => {

      if (dataSource === "favourites") {
        favArray.push(item.favouriteUser.id)
        lastChar = item.favouriteUser.lastName ? item.favouriteUser.lastName.charAt(0).toUpperCase() : null;
      } else {
        lastChar = item.lastName ? item.lastName.charAt(0).toUpperCase() : null;
      }
      if (lastChar && (lastChar !== prevCharacter)) {
        switch (lastChar) {
          case "A":
            alphaIndex.a = i
            prevCharacter = lastChar
            break;
          case "B":
            alphaIndex.b = i
            prevCharacter = lastChar
            break;
          case "C":
            alphaIndex.c = i
            prevCharacter = lastChar
            break;
          case "D":
            alphaIndex.d = i
            prevCharacter = lastChar
            break;
          case "E":
            alphaIndex.e = i
            prevCharacter = lastChar
            break;
          case "F":
            alphaIndex.f = i
            prevCharacter = lastChar
            break;
          case "G":
            alphaIndex.g = i
            prevCharacter = lastChar
            break;
          case "H":
            alphaIndex.h = i
            prevCharacter = lastChar
            break;
          case "I":
            alphaIndex.i = i
            prevCharacter = lastChar
            break;
          case "J":
            alphaIndex.j = i
            prevCharacter = lastChar
            break;
          case "K":
            alphaIndex.k = i
            prevCharacter = lastChar
            break;
          case "L":
            alphaIndex.l = i
            prevCharacter = lastChar
            break;
          case "M":
            alphaIndex.m = i
            prevCharacter = lastChar
            break;
          case "N":
            alphaIndex.n = i
            prevCharacter = lastChar
            break;
          case "O":
            alphaIndex.o = i
            prevCharacter = lastChar
            break;
          case "P":
            alphaIndex.p = i
            prevCharacter = lastChar
            break;
          case "Q":
            alphaIndex.q = i
            prevCharacter = lastChar
            break;
          case "R":
            alphaIndex.r = i
            prevCharacter = lastChar
            break;
          case "S":
            alphaIndex.s = i
            prevCharacter = lastChar
            break;
          case "T":
            alphaIndex.t = i
            prevCharacter = lastChar
            break;
          case "U":
            alphaIndex.u = i
            prevCharacter = lastChar
            break;
          case "V":
            alphaIndex.v = i
            prevCharacter = lastChar
            break;
          case "W":
            alphaIndex.w = i
            prevCharacter = lastChar
            break;
          case "X":
            alphaIndex.x = i
            prevCharacter = lastChar
            break;
          case "Y":
            alphaIndex.y = i
            prevCharacter = lastChar
            break;
          case "Z":
            alphaIndex.z = i
            prevCharacter = lastChar
            break;

          default:
            break;
        }
      }
    })
    if (dataSource === "contacts") {
      setAlphaIndexContacts(alphaIndex)
    }
    else if (dataSource === "pcp") {
      setAlphaIndexPcp(alphaIndex)
    }
    else if (dataSource === "favourites") {
      setAlphaIndexFavourites(alphaIndex)
      setFavArray(favArray)
    }
  }

  const getUserData = () => {
    let lastName = search ? search : null;
    getUserList({ lastName, sortBy }).then((res) => {
      if (responseHandler(res)) {
        setUsers(res.results);
        findIndex(res.results, "contacts")
      }
    });
  };

  const getFavoritesData = (search) => {
    let lastName = search ? search : null;
    getFavorites({ lastName, sortBy }).then((res) => {
      if (responseHandler(res)) {
        setFavorites(res);
        findIndex(res, "favourites")
      }
    });
  }

  const getFavoritesOfUser = (id) => {
    let favouriteId = id ? id : null;
    let userId = loggedUser.id
    if (favouriteId) {
      getFavorites({ favouriteId, userId }).then((res) => {
        if (responseHandler(res)) {
          if (res.length) {
            setFavId(res[0]._id)
          } else {
            setFavId(false)
          }
        }
      });
    }
  }

  const getPcpData = (search) => {
    let lastName = search ? search : null;
    let pcp = true;
    getUserList({ pcp, lastName, sortBy }).then((res) => {
      if (responseHandler(res)) {
        setPcp(res.results);
        findIndex(res.results, "pcp")
      }
    });
  };

  const removeUser = (id) => {
    deleteUser(id).then((res) => {
      if (responseHandler(res)) {
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "success",
        });
        getUserData();
      }
    });
  }

  const deleteMyContact = () => {
    deleteUser(loggedUser.id).then((res) => {
      if (responseHandler(res)) {
        // setNotify({
        //   isOpen: true,
        //   message: "Deleted Successfully",
        //   type: "success",
        // });
        localStorage.clear();
    if (env === 4 || env === 2) {
      window.location.replace(`${hostConfig.API_URL}auth/google`);
    } else {
      window.location.href = "/login";
    }
      }
    });
    
  }
  
  const addFavIcon = (user) => {
    let data = {
      userId: loggedUser.id,
      favouriteId: user.id
    }
    postFavorites(data).then((res) => {
      if (responseHandler(res)) {
        setFavId(res.id)
      }
      getFavoritesData();
    });
  }

  const removeFavIcon = (favId) => {
    deleteFavorites(favId).then((res) => {
      if (responseHandler(res)) {
        getFavoritesData();
      }
    });
  }

  const addUser = () => {
    if (values.firstName === "" || values.firstName === null || values.firstName === undefined) {
      delete values.firstName
    }
    if (values.lastName === "" || values.lastName === null || values.lastName === undefined) {
      delete values.lastName
    }
    if (values.specialty === "" || values.specialty === null || values.specialty === undefined) {
      delete values.specialty
    }
    if (values.location === "" || values.location === null || values.location === undefined) {
      delete values.location
    }
    if (values.mobileNumber === "" || values.mobileNumber === null || values.mobileNumber === undefined) {
      delete values.mobileNumber
    }
    if (values.pager === "" || values.pager === null || values.pager === undefined) {
      delete values.pager
    }
    if (values.fax === "" || values.fax === null || values.fax === undefined) {
      delete values.fax
    }
    if (values.officePhone === "" || values.officePhone === null || values.officePhone === undefined) {
      delete values.officePhone
    }
    if (values.email === "" || values.email === null || values.email === undefined) {
      delete values.email
    }
    if (values.password === "" || values.password === null || values.password === undefined) {
      delete values.password
    }

    postUserData(values).then((res) => {
      if (responseHandler(res)) {
        if (res.code && res.code === 200) {
          getUserData();
          window.location.href = "/users"
        } else {
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
          getUserData();
          window.location.href = "/users"
        }
      }
    });
  }

  const editUser = (detailsToEdit) => {
    let data = { ...detailsToEdit }
    const updateId = data.id;
    delete data.isDeleted
    delete data.createdAt
    delete data.id

    updateUserData(data, updateId).then((res) => {
      if (responseHandler(res)) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        getUserData();
        window.location.href = "/users"
      }
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let temp = value
    if (name === "mobileNumber" || name === "officePhone" || name === "pager" || name === "fax") {
      let x = temp.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      temp = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
    setValues({
      ...values,
      [name]: temp,
    });

  }

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    let temp = value
    if (name === "mobileNumber" || name === "officePhone" || name === "pager" || name === "fax") {
      let x = temp.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      temp = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
    setCurrentUser({
      ...currentUser,
      [name]: temp,
    });

  }

  const searchUser = (user) => {
    setSearch(user)
    if (user) {
      let search = user
      getUserList({ search, sortBy }).then((res) => {
        if (responseHandler(res)) {
          setUsers(res.results);
        }
      });
    } else {
      getUserData()
    }
  }

  const searchPcp = (user) => {
    setSearch(user)
    if (user) {
      let search = user;
      let pcp = true
      getUserList({ search, pcp, sortBy }).then((res) => {
        if (responseHandler(res)) {
          setPcp(res.results);
        }
      });
    } else {
      getPcpData()
    }
  }

  const searchFavorites = (user) => {
    setSearch(user)
    if (user) {
      let search = user
      getFavorites({ search }).then((res) => {
        if (responseHandler(res)) {
          setFavorites(res);
        }
      });
    } else {
      getFavoritesData()
    }
  }

  const responseHandler = (res) => {
    if (res) {
      if (res.code) {
        if (res.code === 400) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
          return false;
        } else if (res.code === 200) {
          return true;
        } else {
          setNotify({
            isOpen: true,
            message: "Bad Request",
            type: "error",
          });
          return false;
        }
      } else if (res.error) {
        setNotify({
          isOpen: true,
          message: res.error,
          type: "error",
        });
        return false;
      } else {
        return res;
      }
    } else {
      return false;
    }
  };

  const changeUserDetails = (user, fav) => {
    setCurrentUser(user);
    if (fav) {
      setFavId(fav)
    } else {
      getFavoritesOfUser(user.id)
    }
  }
  const resetData = () => {
    setSearch("")
    getFavoritesData()
    getUserData()
    getPcpData()
  }
  /***********************************Find  User Role****************************************/
  if (JSON.parse(localStorage.getItem("loggedUser"))) {
    let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser.lastName && loggedUser.firstName) {
      if (window.location.pathname === "/") {
        window.location.href = "/users";
      }
    } else {
      window.location.href = "/edit-my-contact";
    }
  } else {
    const location = window.location.pathname;
    if (location !== "/login") {
      if (location === "/google/auth" || location === "/unauthorized") {
      } else {
        if (env === 4 || env === 2) {
          window.location.href = `${hostConfig.API_URL}auth/google`
        } else {
          window.location.href = "/login";
        }
      }
    }
  }
  /***********************************Return App****************************************/
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className={`${classes.root} App`}>
          <main className={classes.content} id="main">
            <Switch >
              <Route exact path="/">
                {() => {
                  if (env === 4 || env === 2) {
                    window.location.replace(`${hostConfig.API_URL}auth/google`);
                  } else {
                    window.location.href = "/login";
                  }
                }}
              </Route>
              <Route path="/login">
                <Login responseHandler={responseHandler} />
              </Route>
              <Route path="/add-user">
                <AddUser addUser={addUser}
                  handleChange={handleInputChange}
                  recordForEdit={values} />
              </Route>
              <Route path="/edit-user">
                <EditUser
                  currentUser={currentUser}
                  editUser={editUser}
                  handleChange={handleEditChange} />
              </Route>
              <Route path="/edit-my-contact">
                <EditMyContact
                  currentUser={currentUser}
                  editUser={editUser}
                  handleChange={handleEditChange}
                  deleteMyContact={deleteMyContact}
                />
              </Route>
              <Route path="/user-details">
                <UserDetails responseHandler={responseHandler}
                  removeUser={removeUser}
                  currentUser={currentUser}
                  addFavIcon={addFavIcon}
                  removeFavIcon={removeFavIcon}
                  favId={favId}
                  favArray={favArray}
                />
              </Route>
              <Route path="/favorites">
                <Favorites
                  responseHandler={responseHandler}
                  users={favorites} search={search}
                  searchUser={searchFavorites}
                  changeUserDetails={changeUserDetails}
                  alphaIndex={alphaIndexFavourites}
                  resetData={resetData}
                />
              </Route>
              <Route path="/pcp">
                <Pcp
                  responseHandler={responseHandler}
                  users={pcp} search={search}
                  searchUser={searchPcp}
                  changeUserDetails={changeUserDetails}
                  alphaIndex={alphaIndexPcp}
                  resetData={resetData}
                />
              </Route>
              <Route path="/users">
                <Users responseHandler={responseHandler}
                  users={users} search={search}
                  searchUser={searchUser}
                  changeUserDetails={changeUserDetails}
                  getUserData={getUserData}
                  alphaIndex={alphaIndexContacts}
                  resetData={resetData}
                />
              </Route>
              <Route path="/time-out">
                <TimeOut />
              </Route>
              <Route path="/google/auth">
                <GoogleAuth />
              </Route>
              <Route path="/404">
                <NotFound />
              </Route>
              <Route path="/unauthorized">
                <Unauthorized responseHandler={responseHandler} />
              </Route>
              <Route path="*">
                <Redirect to="/404" />
              </Route>
            </Switch>
            <Notification notify={notify} setNotify={setNotify} />
          </main>
        </div>
      </Router>
    </ThemeProvider>
  );
}
