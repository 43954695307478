 import FooterCard from "../FooterCard/FooterCard";
import {AccountCircle,Favorite,Star} from '@material-ui/icons';

import "./Footer.css";

export default function Footer({resetData}) {
    return (
        <> 
        <br/><br/><br/><br/>
        <div className="Footer">
            <FooterCard link="favorites" name="My Favorites" icon={<Favorite/>}  resetData={resetData}/>
            <FooterCard link="pcp" name="Primary Care" icon={<Star/>} resetData={resetData}/>
            <FooterCard link="users" name="Contacts" icon={<AccountCircle/>} resetData={resetData} />
        </div>
        </>
    )
}