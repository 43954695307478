import "./Header.css";
const Header = (props) => {
    const { leftText, lLink, middleText, rightText, rLink } = props;

    return (
        <div className="Header">
            <div className="box" onClick={lLink} ><span>{leftText}</span></div>
            <div className="box"><span>{middleText}</span></div>
            <div className="box" onClick={rLink} ><span>{rightText}</span></div>
        </div>
    )
}
export default Header;