/******************************** Import Package ************************************/
import Input from "./Input.jsx";
import Checkbox from "./Checkbox";
import Button from "./Button";
import ActionButton from "./ActionButton";
import Password from "./Password";
import SelectValue from "./SelectValue";

const Controls = {
  Input,
  Checkbox,
  Button,
  ActionButton,
  Password,
  SelectValue,
};

export default Controls;
