import { makeStyles } from "@material-ui/core/styles";

export const useAppStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        color: "black",
      },
      palette: {
        primary: {
          main: "#9acad6 ",
        },
        secondary: {
          main: "black",
        },
      },
      primary: {
        "&:hover": {
          backgroundColor: "#9acad6 ",
          color: "black",
        },
      },
      content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
      },
    }));