/******************************** Import Package ************************************/
import React from "react";
import { TextField,InputAdornment } from "@material-ui/core";

/******************************** Input ************************************/

export default function Input(props) {
  const { name, label, value, error = null, onChange,amount, ...other } = props;
  return (
    <TextField
      variant="outlined"
      label={ label }
      name={ name }
      value={ value }
      onChange={ onChange }
      InputProps={ {
        startAdornment: amount===true?<InputAdornment position="start">$</InputAdornment>:null
      } }
      { ...other }
      { ...(error && { error: true, helperText: error }) }
    />
  );
}
