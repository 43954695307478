/******************************** Import Package ************************************/
import React from "react";
import { FormControl, FormControlLabel } from "@material-ui/core";
import { Checkbox as MuiCheckbox } from "@material-ui/core";


/******************************** Check Box  ************************************/
export default function Checkbox(props) {
  const { name, label, value, onChange } = props;
  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  
  return (
    <FormControl >
      <FormControlLabel
        control={
          <MuiCheckbox
            name={ name }
            style={{color:"#4e9a98"}}
            checked={ value }
            onChange={ (e) =>
              onChange(convertToDefEventPara(name, e.target.checked))
            }
          />
        }
        label={ label }
      />
    </FormControl>
  );
}
