/**Display last name , first  name**/
const displayName = (user) => {
  if (user.firstName) {
    if (user.lastName) {
      let result = <span>{user.lastName + ", "}<b>{user.firstName}</b></span>
      return result;
    } else {
      return <b>{user.firstName}</b>
    }
  } else {
    return "-";
  }
}

const getInitials = (user) => {
  if (user) {
    if (user.firstName) {
      if (user.lastName) {
        return user.lastName.charAt(0) + user.firstName.charAt(0);
      } else {
        return "#"
      }
    } else {
      return ""
    }
  } else {
    return ""
  }
}

const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const downloadToVCard = (filename, data) => {
  let text = `BEGIN:VCARD\nVERSION:2.1\nN:${data.lastName};${data.firstName}\nFN:${data.firstName} ${data.lastName}\nORG:PIMG\n${data.title ? `TITLE:${data.title}` : null}\nTEL;WORK;VOICE:${data.officePhone}\nTEL;HOME;VOICE:${data.mobileNumber}\nTEL;CELL;VOICE:${data.mobileNumber}\nTEL;WORK;FAX:${data.pager}\nADR;WORK:;PIMG;;${data.location};USA\nURL;WORK:http://www.contactswebsiteURL.com\nEMAIL;INTERNET:${data.email}\nNOTE:${data.notes || ""}\nEND:VCARD`
  let element = document.createElement('a');
  element.setAttribute('href', 'data:text/x-vcard;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}


export {
  displayName,
  alphabets,
  getInitials,
  downloadToVCard,
};
