import React, { useState, useEffect } from "react";
import { Card } from "@material-ui/core";

import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import DetailsCard from "../../Components/DetailsCard/DetailsCard";
import Menu from "../../Components/Menu/Menu";
import { Favorite, FavoriteBorder, ArrowBack } from "@material-ui/icons";
import { displayName, getInitials } from "../../Utils/pillarFunctions";
import "./UserDetails.css";
import { activeStatusCallTime } from "../../Config";
import { getActiveStatus } from "../../Api/list";

export default function UserDetails(props) {
  const {
    currentUser,
    addFavIcon,
    removeFavIcon,
    removeUser,
    favArray,
    favId,
  } = props;
  const [favicon, setFavicon] = useState(favArray.includes(currentUser.id));
  const changeFavIcon = () => {
    if (favicon === true) {
      removeFavIcon(favId);
    } else {
      addFavIcon(currentUser);
    }
    setFavicon(!favicon);
  };
  const deleteUser = () => {
    removeUser(currentUser.id);
    window.history.back();
  };
  useEffect(() => {
    getActiveStatus("");
    let intervalId = setInterval(() => {
      getActiveStatus("");
    }, activeStatusCallTime);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="UserDetails ">
      <Header
        leftText={
          <span className="back-btn" onClick={() => window.history.back()}>
            <ArrowBack className="theme" />
          </span>
        }
        middleText="Contact Details"
        rightText={<Menu currentUser={currentUser} deleteUser={deleteUser} />}
      />
      <br />
      <div className="flex " style={{ position: "relative" }}>
        <Card className=" flex pageIcon upperCase">
          {getInitials(currentUser)}
        </Card>
        <span onClick={changeFavIcon} className="float-right absolute r-1r">
          {favicon ? (
            <Favorite className="theme pointer fav-icon" />
          ) : (
            <FavoriteBorder className="theme pointer fav-icon" />
          )}
        </span>
      </div>
      <div className="flex user-details-name">
        <h4 className="capitalize mt-1">{displayName(currentUser)} </h4>
      </div>
      {currentUser.mobileNumber ? (
        <DetailsCard
          title="Mobile Phone"
          sms={true}
          currentUser={currentUser}
          phone={currentUser.mobileNumber}
        />
      ) : null}
      {currentUser.pager ? (
        <DetailsCard title="Pager" phone={currentUser.pager} />
      ) : null}
      {currentUser.officePhone ? (
        <DetailsCard title="Office Phone" phone={currentUser.officePhone} />
      ) : null}
      {currentUser.fax ? (
        <DetailsCard title="Fax" data={<span>{currentUser.fax}</span>} />
      ) : null}
      {currentUser.email ? (
        <DetailsCard title="Email" mail={currentUser.email} />
      ) : null}
      {currentUser.title ? (
        <DetailsCard title="Title" data={<span>{currentUser.title}</span>} />
      ) : null}
      {currentUser.specialty ? (
        <DetailsCard
          title="Specialty"
          data={<span>{currentUser.specialty}</span>}
        />
      ) : null}
      {currentUser.notes ? (
        <DetailsCard title="Notes" data={<span>{currentUser.notes}</span>} />
      ) : null}
      <Footer />
    </div>
  );
}
