import React, { useState } from 'react';
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MoreVert, Delete, Edit, GetAppOutlined } from '@material-ui/icons';
import ConfirmDialog from "../../Components/ConfirmDialog";

import { downloadToVCard } from "../../Utils/pillarFunctions";

import "./Menu.css"

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const { deleteUser, currentUser } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div >
      <IconButton
        style={{ padding: "0px" }}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        className="Menu"
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        <Link to="/edit-user" className="flex edit-menu">
          <MenuItem>
            <Edit />Edit
        </MenuItem>
        </Link>

        <MenuItem style={{ color: "red" }}
          onClick={() => {
            setConfirmDialog({
              isOpen: true,
              title: "Are you sure ?",
              subTitle: "You can't undo this operation",
              onConfirm: () => {
                setConfirmDialog({ ...confirmDialog, isOpen: false });
                deleteUser();
              },
            });
          }}>
          <Delete />Delete
          </MenuItem>

        <MenuItem style={{ color: "#4e9a98" }}
          onClick={() => downloadToVCard(`${currentUser.lastName ? currentUser.lastName : null},${currentUser.firstName ? currentUser.firstName : null}.vcf`, currentUser)}>
          <GetAppOutlined />Export
          </MenuItem>
      </Menu>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}