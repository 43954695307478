import { Link } from "react-router-dom";
import "./FooterCard.css";
const FooterCard = (props) => {
    const { link, name, icon,resetData } = props;

    return (
            <Link to={`/${link}`} onClick={resetData} className="FooterCard" >
                {icon}
                <p>{name}</p>
            </Link>
    )
}
export default FooterCard;