import { Box } from "@material-ui/core";
import { Call, EmailTwoTone, ChatTwoTone } from "@material-ui/icons";

export default function DetailsCard(props) {
  const { title, data, phone, mail, sms, currentUser } = props;

  return (
    <Box boxShadow={3} bgcolor="background.paper" m={1} p={1}>
      <h4>{title}</h4>
      {data ? <p className="">{data}</p> : null}
      {phone ? (
        <span className="flex-between">
          <a href={`tel:${phone}`} className="minimum-character">
            {phone}
          </a>
          {sms ? (
            <div>
              <a href={`tel:${phone}`}>
                <Call />
                &emsp;
              </a>
              <a
                className="minimum-character"
                href={`sms:${phone}${"&?"}body=hi, \nI am ${
                  currentUser.lastName ? currentUser.lastName : null
                }${currentUser.lastName ? "," : null} ${
                  currentUser.firstName
                } from PIMG`}
              >
                <ChatTwoTone />
              </a>
            </div>
          ) : (
            <a className="minimum-character" href={`tel:${phone}`}>
              <Call />
            </a>
          )}
        </span>
      ) : null}

      {mail ? (
        <span className="flex-between">
          <a href={`mailto:${mail}`} className="minimum-character">
            {mail}
          </a>
          <a href={`mailto:${mail}`}>
            <EmailTwoTone />
          </a>
        </span>
      ) : null}
    </Box>
  );
}
