/******************************** Import Package ************************************/
import React from "react";
import { FadeLoader } from "react-spinners";

/******************************** loader ************************************/
const Loader = ({ loading }) => {
  return (
    <div className="center">
      <FadeLoader color="#4e9a98" loading={ loading } size={ 150 } />
    </div>
  );
};
export default Loader;
