import React from "react";
import { Link } from "react-router-dom";
import { displayName, alphabets, } from "../../Utils/pillarFunctions";

import { VariableSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

import NotFound from "../../Assets/images/no-records.jpg";
const UserTable = (props) => {
  const { users, changeUser, alphaIndex, resetData } = props;

  const listRef = React.createRef();

  const rowHeights = new Array(users.length)
    .fill(true)
    .map(() => 35
    );

  const getItemSize = index => rowHeights[index];
  const items = [...users]

  const Row = ({ index, style }) => {

    return (
      <Link to="/user-Details"
        style={style}
        onClick={() => handleChange(items[index])}
        key={items[index].favouriteUser ? items[index].favouriteUser.id : items[index].id}
        id={items[index].lastName ? items[index].lastName.charAt(0).toUpperCase() : null}
      >
        <div className="list-item">
          <div className="user-name">
            <p>{displayName(items[index].favouriteUser ? items[index].favouriteUser : items[index])}</p>
          </div>
        </div>
      </Link>
    )
  };
  const handleChange = (user) => {
    resetData()
    changeUser(user.favouriteUser ? user.favouriteUser : user, user.favouriteUser ? user.id : null)
  }

  const GotoLetter = (letter) => {
    switch (letter) {
      case "A":
        return (alphaIndex.a === 0 || alphaIndex.a) ?
          listRef.current.scrollToItem(alphaIndex.a, "start") : false;
      case "B":
        return (alphaIndex.b === 0 || alphaIndex.b) ?
          listRef.current.scrollToItem(alphaIndex.b, "start") : false;
      case "C":
        return (alphaIndex.c === 0 || alphaIndex.c) ?
          listRef.current.scrollToItem(alphaIndex.c, "start") : false;
      case "D":
        return (alphaIndex.d === 0 || alphaIndex.d) ?
          listRef.current.scrollToItem(alphaIndex.d, "start") : false;
      case "E":
        return (alphaIndex.e === 0 || alphaIndex.e) ?
          listRef.current.scrollToItem(alphaIndex.e, "start") : false;
      case "F":
        return (alphaIndex.f === 0 || alphaIndex.f) ?
          listRef.current.scrollToItem(alphaIndex.f, "start") : false;
      case "G":
        return (alphaIndex.g === 0 || alphaIndex.g) ?
          listRef.current.scrollToItem(alphaIndex.g, "start") : false;
      case "H":
        return (alphaIndex.h === 0 || alphaIndex.h) ?
          listRef.current.scrollToItem(alphaIndex.h, "start") : false;
      case "I":
        return (alphaIndex.i === 0 || alphaIndex.i) ?
          listRef.current.scrollToItem(alphaIndex.i, "start") : false;
      case "J":
        return (alphaIndex.j === 0 || alphaIndex.j) ?
          listRef.current.scrollToItem(alphaIndex.j, "start") : false;
      case "K":
        return (alphaIndex.k === 0 || alphaIndex.k) ?
          listRef.current.scrollToItem(alphaIndex.k, "start") : false;
      case "L":
        return (alphaIndex.l === 0 || alphaIndex.l) ?
          listRef.current.scrollToItem(alphaIndex.l, "start") : false;
      case "M":
        return (alphaIndex.m === 0 || alphaIndex.m) ?
          listRef.current.scrollToItem(alphaIndex.m, "start") : false;
      case "N":
        return (alphaIndex.n === 0 || alphaIndex.n) ?
          listRef.current.scrollToItem(alphaIndex.n, "start") : false;
      case "O":
        return (alphaIndex.o === 0 || alphaIndex.o) ?
          listRef.current.scrollToItem(alphaIndex.o, "start") : false;
      case "P":
        return (alphaIndex.p === 0 || alphaIndex.p) ?
          listRef.current.scrollToItem(alphaIndex.p, "start") : false;
      case "Q":
        return (alphaIndex.q === 0 || alphaIndex.q) ?
          listRef.current.scrollToItem(alphaIndex.q, "start") : false;
      case "R":
        return (alphaIndex.r === 0 || alphaIndex.r) ?
          listRef.current.scrollToItem(alphaIndex.r, "start") : false;
      case "S":
        return (alphaIndex.s === 0 || alphaIndex.s) ?
          listRef.current.scrollToItem(alphaIndex.s, "start") : false;
      case "T":
        return (alphaIndex.t === 0 || alphaIndex.t) ?
          listRef.current.scrollToItem(alphaIndex.t, "start") : false;
      case "U":
        return (alphaIndex.u === 0 || alphaIndex.u) ?
          listRef.current.scrollToItem(alphaIndex.u, "start") : false;
      case "V":
        return (alphaIndex.v === 0 || alphaIndex.v) ?
          listRef.current.scrollToItem(alphaIndex.v, "start") : false;
      case "W":
        return (alphaIndex.w === 0 || alphaIndex.w) ?
          listRef.current.scrollToItem(alphaIndex.w, "start") : false;
      case "X":
        return (alphaIndex.x === 0 || alphaIndex.x) ?
          listRef.current.scrollToItem(alphaIndex.x, "start") : false;
      case "Y":
        return (alphaIndex.y === 0 || alphaIndex.y) ?
          listRef.current.scrollToItem(alphaIndex.y, "start") : false;
      case "Z":
        return (alphaIndex.z === 0 || alphaIndex.z) ?
          listRef.current.scrollToItem(alphaIndex.z, "start") : false;

      default:
        return null;
    }
  }

  return (
    <div className="table-container">
      <div className="table-header">Contacts</div>
      {users.length ?
        <div className="alphabetical-table">
          <div className="contacts-table" >

            <AutoSizer>
              {({ height, width }) => (
                <List
                  ref={listRef}
                  height={height}
                  itemCount={users.length}
                  itemSize={getItemSize}
                  width={width}
                  itemData={users}
                  {...props}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          </div>
          <div className="alphabet-search capitalize">
            {alphabets.map((letter) => (
              <div className="letter" key={letter}
                onMouseEnter={() => GotoLetter(letter)}
                onTouchStart={() => GotoLetter(letter)}
                onTouchMove={() => GotoLetter(letter)}
              >
                {letter}
              </div>
            ))}
          </div>
        </div>
        : <div className="flex no-records">
          <img src={NotFound} alt="No Records Found" />
          <h3>No Contacts Found</h3>
          <hr />
        </div>}
    </div>
  )
}
export default UserTable;