/******************************** Import Package ************************************/
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
/******************************* Import Components **********************************/
import SearchBar from "../../Components/SearchBar";
import Header from "../../Components/Header/Header";
/****************************** Import Sub-Components *******************************/
import Footer from "../../Components/Footer/Footer";
import UserTable from "../../Components/UserTable/UserTable";

/****************************** Import Utils ****************************************/
import { refreshInterval } from "../../Config";
import { Add } from '@material-ui/icons';
import Logo from "../../Assets/icons/logoIcon.svg";
import { activeStatusCallTime } from "../../Config";
import { getActiveStatus } from "../../Api/list";

export default function Users({
  users, search, searchUser, changeUserDetails, getUserData, alphaIndex, resetData }) {

  useEffect(() => {
    let interval = setInterval(() => {
      getUserData(search);
    }, refreshInterval);
    return () => clearInterval(interval);
    //refreshInterval needed here if we change it in config file otherwise the server needs to be restarted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshInterval, search, getUserData]);

  useEffect(() => {
    getActiveStatus("")
    const intervalId = setInterval(() => {
      getActiveStatus("")
    }, activeStatusCallTime)
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Header
        leftText={<img src={Logo} alt="PIMG logo" />}
        middleText="Contacts"
        rightText={<Link to="/add-user" className="flex" onClick={resetData}>
          <Add />
           Add
           </Link>} />
      <SearchBar
        title="User List"
        placeholder="Search"
        handleData={searchUser}
        searchField="lastName"
      />
      <UserTable users={users} changeUser={changeUserDetails}
        alphaIndex={alphaIndex} resetData={resetData} />
      <Footer resetData={resetData} />
    </>
  );
}
