/******************************** Import Package ************************************/
import React, { useState } from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Controls from "../../Components/Controls";
import Logo from "../../Assets/icons/logo.svg";

import {
  Typography,
  Box,
  TextField,
  CssBaseline,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { postLoginRequestData } from "../../Api/create";

import Notification from "../../Components/Notification";
import "./login.css";
import GoogleLogo from "./googleLogo.png";
import { hostConfig } from "../../Config";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  maingrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: "1",
    position: "absolute",
    top: 0,
    left: 0,
    margin: "0px",
    padding: "0px",
    width: "100%",
    height: "auto",
    backgroundColor: "#9acad6",
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const { handleSubmit, register } = useForm();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  if (JSON.parse(localStorage.getItem("loggedUser"))) {
    window.location.href = "/users";
  }

  const onSubmit = handleSubmit((data) => {
    if (data.email && data.password) {
      postLoginRequestData(data).then((res) => {
        if (responseHandler(res)) {
          let loggedUser = res.user;
          let accessToken = res.tokens.access.token;
          let refreshToken = res.tokens.refresh.token;
          let accessExpiry = res.tokens.access.expires;
          let refreshExpiry = res.tokens.refresh.expires;
          localStorage.setItem("accessExpiry", accessExpiry);
          localStorage.setItem("refreshExpiry", refreshExpiry);
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
          if (JSON.parse(localStorage.getItem("loggedUser"))) {
            setNotify({
              isOpen: true,
              message: "Logged in successfully",
              type: "success",
            });
            window.location.href = "/users";
          } else {
            setNotify({
              isOpen: true,
              message: "Invalid details",
              type: "error",
            });
          }
        }
      });
    } else {
      setNotify({
        isOpen: true,
        message: "Invalid details",
        type: "error",
      });
    }
  });

  const responseHandler = (res) => {
    if (res) {
      if (res.code) {
        if (res.code === 400) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
          return false;
        } else {
          setNotify({
            isOpen: true,
            message: "Bad Request",
            type: "error",
          });
          return false;
        }
      } else if (res.error) {
        setNotify({
          isOpen: true,
          message: res.error,
          type: "error",
        });
        return false;
      } else {
        return res;
      }
    } else {
      return false;
    }
  };

  return (<>
    <div className="Login">
      <CssBaseline />
      <img src={Logo} alt="logo" width="100%" />
      <br />
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
            </Typography>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          inputRef={register}
          autoFocus
          className={`${classes.textField} form-textField`}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label="Password"
          type="password"
          inputRef={register}
          id="password"
          autoComplete="current-password"
          className={`${classes.textField} form-textField`}
        />
        <br />
        <Controls.ActionButton
          type="submit"
          variant="contained"
          color="primary"
          className={`${classes.submit} form-textField`}
        >
          Sign In
              </Controls.ActionButton >
      </form>
      <br />
      <a href={`${hostConfig.API_URL}auth/google`}>
        <img src={GoogleLogo} alt="Google sign in" className="pointer" />
      </a>
      <Box mt={2}>
        <center>Last Update: 23/Mar/2021</center>
        <center>Data Version: V9</center>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  </>
  );
};

export default SignIn;
