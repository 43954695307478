/******************************** Import Package ************************************/
/******************************** Import Package ************************************/
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../Components/Controls";
import { useForm, Form } from "../../Components/useForm";

import Notification from "../../Components/Notification";

import Header from "../../Components/Header/Header";
import { activeStatusCallTime } from "../../Config";
import { getActiveStatus } from "../../Api/list";

const initialFValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  title: "",
  specialty: "",
  officePhone: "",
  pager: "",
  fax: "",
  notes: "",
};

export default function AddUser(props) {
  const { editUser, currentUser, handleChange, deleteMyContact } = props;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  useEffect(() => {
    getActiveStatus("")
    let intervalId = setInterval(() => {
      getActiveStatus("")
    }, activeStatusCallTime)
    return () => clearInterval(intervalId);
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("firstName" in fieldValues) { temp.firstName = fieldValues.firstName ? "" : "Please Enter Your First Name "; }

    if ("lastName" in fieldValues) { temp.lastName = fieldValues.lastName ? "" : "Please Enter Your Last Name"; }

    if ("email" in fieldValues) {
      if (!/$^|.+@.+..+/.test(fieldValues.email)) { temp.email = "Please Enter Valid Email"; }
      else { temp.email = ""; }
    }
    setErrors({ ...temp });

    if (fieldValues === values) { return Object.values(temp).every((x) => x === ""); }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      let firstName = values.firstName
      let lastName = values.lastName
      if (firstName.trim() === "") delete values.firstName
      if (lastName.trim() === "") delete values.lastName
      editUser(values, resetForm);
    }
  };

  useEffect(() => {
    if (currentUser != null) {
      setValues({
        ...currentUser,
      });
    }
  }, [currentUser, setValues]);

  const handleValueChange = (e) => {
    handleInputChange(e)
    handleChange(e)
  }
  return (
    <>
      <Form onSubmit={handleSubmit} >
        <Header
          middleText="Edit My Contact"
        />
        <br />
        <Grid container  >
          <Grid item xs={12} sm={6} className="flex">
            <Controls.Input
              autoFocus
              name="firstName"
              label="First Name"
              value={values.firstName || ""}
              onChange={handleValueChange}
              error={errors.firstName}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex">
            <Controls.Input
              name="lastName"
              label="Last Name"
              value={values.lastName || ""}
              onChange={handleValueChange}
              error={errors.lastName}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex">
            <Controls.Input
              label="Email"
              name="email"
              value={values.email || ""}
              onChange={handleValueChange}
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex" >
            <Controls.Input
              label="Specialty"
              name="specialty"
              value={values.specialty || ""}
              onChange={handleValueChange}
              error={errors.specialty}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex" >
            <Controls.Input
              label="Title"
              name="title"
              value={values.title || ""}
              onChange={handleValueChange}
              error={errors.title}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex" >
            <Controls.Input
              label="Mobile"
              name="mobileNumber"
              value={values.mobileNumber || ""}
              onChange={handleValueChange}
              error={errors.mobileNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex" >
            <Controls.Input
              label="Office Phone"
              name="officePhone"
              value={values.officePhone || ""}
              onChange={handleValueChange}
              error={errors.officePhone}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex" >
            <Controls.Input
              label="Pager"
              name="pager"
              value={values.pager || ""}
              onChange={handleValueChange}
              error={errors.pager}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="flex" >
            <Controls.Input
              label="Fax"
              name="fax"
              value={values.fax || ""}
              onChange={handleValueChange}
              error={errors.fax}
            />
          </Grid>

          <Grid item xs={12} sm={12} className="flex multi-line-container" >
            <Controls.Input
              className="multi-line-text multi-line-notes"
              rows={4}
              name="notes"
              label="Notes"
              value={values.notes || ""}
              onChange={handleValueChange}
              error={errors.notes}
              multiline
            />
          </Grid>

          <Grid item xs={false} sm={7} className="flex" >
          </Grid>
          <Grid item xs={12} sm={4} className="submit-clear-btn" >
            <Controls.Button text="Cancel" color="secondary" onClick={deleteMyContact} />
            <Controls.Button type="submit" text="Submit" />
          </Grid>
        </Grid>
        <Notification notify={notify} setNotify={setNotify} />
      </Form>
    </>
  );
}
