import { useEffect } from "react";
import SearchBar from "../../Components/SearchBar";
import Footer from "../../Components/Footer/Footer";
import UserTable from "../../Components/UserTable/UserTable";
import Header from "../../Components/Header/Header";
import Logo from "../../Assets/icons/logoIcon.svg";
import { activeStatusCallTime } from "../../Config";
import { getActiveStatus } from "../../Api/list";

export default function Contacts(props) {
  const { users, searchUser, changeUserDetails,
    alphaIndex, resetData } = props;

  useEffect(() => {
    getActiveStatus("")
    let intervalId = setInterval(() => {
      getActiveStatus("")
    }, activeStatusCallTime)
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Header
        leftText={<img src={Logo} alt="PIMG logo" />}
        middleText="Favorites" />
      <SearchBar
        title="User List"
        placeholder="Search"
        handleData={searchUser}
        searchField="lastName"
      />
      <UserTable users={users} changeUser={changeUserDetails}
        alphaIndex={alphaIndex} resetData={resetData} />
      <Footer resetData={resetData} />
    </>
  )
}