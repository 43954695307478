/******************************** Import Api ************************************/
import { env, hostConfig } from "../Config";
// import { postRefreshTokensData } from "./create";

const token = localStorage.getItem("accessToken");

/******************************** Response Handler ************************************/
const responseStatusHandler = (response) => {

  switch (response.status) {
    case 400:
      return response
    case 401:
      return reLogin()
    case 402:
      return { error: "Payment Required" }
    case 403:
      return { error: "Forbidden" }
    case 404:
      return { error: "Not Found" }
    case 405:
      return { error: "Method Not Allowed" }
    case 406:
      return { error: "Not Acceptable" }
    case 408:
      return { error: "Request Timeout" }
    case 409:
      return { error: "Request Already Exist" }// Conflict
    case 410:
      return { error: "permanently deleted from server" }
    case 500:
      return { error: "Internal Server Error" }
    case 501:
      return { error: "Not Implemented" }
    case 502:
      return { error: "Bad Gateway" }
    case 503:
      return { error: "Service Unavailable" }
    case 504:
      return { error: " Gateway Timeout" }
    case 511:
      return { error: " Network Authentication Required" }
    case 200: case 201:
      return response
    default:
      return false;
  }
}
/******************************** Re-Login if session expired ************************************/
const reLogin = () => {
  localStorage.clear();
  if (env === 4 || env === 2) {
    window.location.replace(`${hostConfig.API_URL}auth/google`);
  } else {
    window.location.href = "/login";
  }
}
/******************************** Error Handler ************************************/
const errorHandler = (error) => {
  return false;
};

/******************************** View with query Api ************************************/
const getListByApi = (requestUrl, params) => {

  let getParams = "?";
  let count = 0;
  if (params && params.corematicaName !== null && params.corematicaName !== undefined) { getParams += `${count === 0 ? "" : "&"}corematicaName=${params.corematicaName}`; count++ }

  if (params && params.requestorEmail !== null && params.requestorEmail !== undefined) { getParams += `${count === 0 ? "" : "&"}requestorEmail=${params.requestorEmail}`; count++ }

  if (params && params.sortBy && params.sortBy !== null && params.sortBy !== undefined) { getParams += `${count === 0 ? "" : "&"}sortBy=${params.sortBy}`; count++ }

  if (params && params.userId && params.userId !== null && params.userId !== undefined) { getParams += `${count === 0 ? "" : "&"}userId=${params.userId}`; count++ }

  if (params && params.favouriteId && params.favouriteId !== null && params.favouriteId !== undefined) { getParams += `${count === 0 ? "" : "&"}favouriteId=${params.favouriteId}`; count++ }

  if (params && params.isActive !== null && params.isActive !== "" && params.isActive !== undefined) { getParams += `${count === 0 ? "" : "&"}isActive=${params.isActive}`; count++ }

  if (params && params.role !== null && params.role !== undefined) { getParams += `${count === 0 ? "" : "&"}role=${params.role}`; count++ }

  if (params && params.search && params.search !== null && params.search !== undefined) { getParams += `${count === 0 ? "" : "&"}search=${params.search}`; count++ }

  if (params && params.pcp !== null && params.pcp !== "" && params.pcp !== undefined) { getParams += `${count === 0 ? "" : "&"}pcp=${params.pcp}`; count++ }

  return fetch(`${hostConfig.API_URL}${requestUrl}${getParams}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return responseStatusHandler(response);
    })
    .then((result) => {
      return result.status === 200 || result.status === 201 || result.status === 400 ?
        result.json() : result
    })
    .catch((error) => {
      errorHandler(error);
    });
};

/******************************** View Api ************************************/
const viewDataByApi = async (requestUrl, dataId) => {
  return await fetch(`${hostConfig.API_URL}${requestUrl}/${dataId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return responseStatusHandler(response);
    })
    .then((result) => {
      return result.status === 200 || result.status === 201 || result.status === 400 ?
        result.json()
        : result
    })
    .catch((error) => {
      errorHandler(error);
    });
};

/******************************** Create Api ************************************/
const postDataApi = async (requestUrl, params,) => {
  return await fetch(`${hostConfig.API_URL}${requestUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return responseStatusHandler(response);
    })
    .then((result) => {
      return result.status === 200 || result.status === 201 || result.status === 400 ?
        result.json()
        : result
    })
    .catch((error) => {
      errorHandler(error);
    });
};

/******************************** Create with query Api ************************************/
const postDataWithParamsApi = async (requestUrl, data, params) => {
  let getParams = "?";
  if (params && params.rowsPerPage && params.rowsPerPage !== undefined) { getParams += `limit=${params.rowsPerPage}`; }

  if (params && params.currentPage && params.currentPage !== undefined) { getParams += `&page=${params.currentPage}`; }
  return await fetch(`${hostConfig.API_URL}${requestUrl}${getParams}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return responseStatusHandler(response);
    })
    .then((result) => {
      return result.status === 200 || result.status === 201 || result.status === 400 ?
        result.json()
        : result
    })
    .catch((error) => {
      errorHandler(error);
    });
};

/******************************** Update Api ************************************/
const putDataApi = async (requestUrl, params, id) => {
  return await fetch(`${hostConfig.API_URL}${requestUrl}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return responseStatusHandler(response);
    })
    .then((result) => {
      return result.status === 200 || result.status === 201 || result.status === 400 ?
        result.json()
        : result
    })
    .catch((error) => {
      errorHandler(error);
    });
};

/******************************** Delete Api ************************************/
const deleteDataApi = async (requestUrl, id) => {
  return await fetch(`${hostConfig.API_URL}${requestUrl}/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return responseStatusHandler(response);
    })
    .then((result) => {
      return result.status === 200 || result.status === 201 || result.status === 400 ?
        result.json()
        : result
    })
    .catch((error) => {
      errorHandler(error);
    });
};

/********************************Export ************************************/
export {
  getListByApi,
  viewDataByApi,
  postDataApi,
  postDataWithParamsApi,
  putDataApi,
  deleteDataApi,
  responseStatusHandler,
};
