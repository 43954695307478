/******************************** Import Package ************************************/
import React from "react";
import { Button, makeStyles } from "@material-ui/core";

/******************************** ActionButton Style ************************************/
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  secondary: {
    backgroundColor: theme.palette.secondary.light,
    "& .MuiButton-label": {
      color:"#ffffff",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& .MuiButton-label": {
        color:"#ffffff",
      },
    },
  },
  primary: {
    backgroundColor: "#4e9a98",
    "& .MuiButton-label": {
      color: "#ffffff",
    },
    "&:hover": {
      backgroundColor: "#87bcb4",
    },
  },
}));




/******************************** ActionButton  ************************************/
export default function ActionButton(props) {
  const { color, children, onClick, disabled, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      className={`${classes.root} ${classes[color]} ${className}`}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
}
