/******************************** Import libs ************************************/

const URL_CONSTANTS = {

  users: "users",
  sponsorList: "users/userSponsor",
  sponsorDetails: "users/sponsor",
  resourceList: "users/userResource",
  resourceDetails: "users/resource",
  favorites: "favourites",

  login: "auth/login",
  register: "auth/register",
  logout: "auth/logout",
  refreshTokens: "auth/refresh-tokens",

  location: "location",
  activeStatus: "users/activeStatus"

};

export { URL_CONSTANTS };
