/******************************** Import Package ************************************/
import React, { useState } from "react";
import { Search, Close } from "@material-ui/icons";
import { makeStyles, Toolbar, InputAdornment } from "@material-ui/core";

/******************************** Import Libs ************************************/
import Controls from "./Controls";

/************************ Styles **************************************************/
const useStyles = makeStyles((theme) => ({
  searchInput: {
    width: "100%",
  },
  closeButton:{
    cursor:"pointer"
  }
}));

// ************************main components***********************
const SearchBar = ({  handleData,placeholder }) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    let title = e.target.value;
    setSearch(title)
    handleData(title);
  };
  const clear = () => {
    setSearch("")
    handleData("");
  }

  return (
    <Toolbar>
      <Controls.Input
        placeholder={placeholder}
        value={search}
        className={`${classes.searchInput} search-input`}
        onChange={(e) => handleSearch(e)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (search.length > 0 ? <InputAdornment position="end"><Close className={classes.closeButton} onClick={clear}
          /></InputAdornment> : null),
        }}
      />
    </Toolbar>
  );
};
export default SearchBar;
